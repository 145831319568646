import React from 'react';
import './KiannaExplainsAI.css';


const KiannaExplainsAI = () => {
  return (
    <section className="explains">
      <div className="explainsContainer">
        <div className="grid">
          <div className="row explains">
            <div className="explainsText">
              <h1 className="title">Kianna Explains AI</h1>
  
              <p>
              A platform created to demystify AI. With this 
              platform, I champion AI literacy, ethical discussion, and responsible AI use, aiming to empower and educate a diverse platform.
              </p>
              <p>
              Through Substack posts, TikTok videos, and Instagram posts, I offer a range of content tailored to engage with everyone regardless 
              of their familiarity with AI. My Substack features in-depth articles and analysis, deep diving into AI concepts, applications, and 
              ethical considerations. On TikTok, I create bite-sized videos that break down AI topics into understandable talking points. My 
              Instagram posts provide quick insights to encourage critical thinking about the role of AI in our lives. Together, these platforms 
              form a comprehensive resource for anyone looking to understand and engage with AI while considering responsibility and ethics.
              </p>
              <a
                className="projectButton"
                href="https://www.kiannaexplainsai.com"
                role="button"
                target="_blank"
              >Check it out
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default KiannaExplainsAI;

