import React from 'react';
import './CV.css';

const CV = () => {
  return (
    <div className="cv-container">
    <h1 className="title">My Experience</h1>
      <div className="download-section">
        <b><a href="/assets/cv.pdf" target="_blank" rel="noopener noreferrer">Download my full CV.</a></b>
      </div>

      <div className="education-section cv-section">
        <h2 className="section-heading">Education</h2>
        
        {/* NCAT Grad */}
        <h3 className="school-title">North Carolina A&amp;T State University, College of Engineering, Greensboro, NC (2023 - present)</h3>
        <p className="degree">Master of Science in Data Science and Engineering</p>
        
        {/* NCAT Undergrad */}
        <h3 className="school-title">North Carolina A&amp;T State University, College of Business and Economics, Greensboro, NC (2020 - 2022)</h3>
        <p className="degree">Bachelor of Science in Economics</p>

        {/* College of the Albemarle */}
        <h3 className="school-title">College of the Albemearle, Elizabeth City, NC (2018 - 2020)</h3>
        <p className="degree">Associate of Arts</p>

      </div>

      <div className="awards-section cv-section">
        <h2 className="section-heading">Awards & Achievements</h2>
        
        {/* CIC */}
        <h3 className="school-title">Columbia University's Covid Information Commons Student Paper Challenge (2023)</h3>
        <p className="degree">2nd Place (Solo Author) in Undergraduate Cohort</p>
        <ul>
            <li>Paper Title: "A Stacking Ensemble Approach Leveraging Plasma Proteomics, Metabolomics, and Clinical Data for COVID-19 Severity Prediction"</li>
        </ul>

      </div>

      <div className="work-section cv-section">
      <h2 className="section-heading">Work</h2>
        
        {/* NCAT Grad */}
        <h3 className="school-title">Activision Blizzard, App UI Dev Team (June 2022 - August 2022)</h3>
        <p className="position">Front-end Development Intern</p>
        <ul>
            <li>Updated and maintained My Call of Duty website using React Native</li>
            <li>Implemented new design on My Call of Duty using React Native</li>
            <li>Conducted research on the feasibility of future Call of Duty Companion app endeavors</li>
        </ul>
      </div>
    </div>
  );
};

export default CV;
