// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import About from './Components/About';
import Projects from './Components/Projects';
import KiannaExplainsAI from './Components/KiannaExplainsAI';
import CV from './Components/CV'; 
import Contact from './Components/Contact';
import './App.css'; 
import EyesOnTheData from './Components/EyesOnTheData';

function App() {
  return (
    <Router>
      <div className="container-or-wrapper-class">
        <Navbar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/kiannaexplainsai" element={<KiannaExplainsAI />} />
          <Route path="/eyesonthedata" element={<EyesOnTheData />} />
          <Route path="/cv" element={<CV />} />
        </Routes>
        <Contact />
      </div>
    </Router>
  );
}

export default App;
