import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import { faFileExcel, faLaptopCode } from '@fortawesome/free-solid-svg-icons';  // Import faLaptopCode from free-solid-svg-icons
import './Projects.css';


const Projects = () => {

  const projectData = [
    {
      title: "Molecular Docking of HIV Biomarkers with Drug and Peptide-based Treatments",
      description: "This project uses computer simulations to study how different drugs interact with HIV-1 protease, a key enzyme that helps the virus replicate. By modeling how these drugs bind to the enzyme, the research aims to identify which ones might be most effective in stopping the virus, including new drugs that could work against drug-resistant forms of HIV.",
      image: "/assets/molecular-docking-class.png",
      icon: faLaptopCode,
      button: "Paper",
      link: "https://docs.google.com/document/d/1lVc95R5q6qHwPduO3SxaZ75iBegriuLS2qIM1tden4k/edit?usp=sharing"
    },
    {
      title: "COVID-19 Severity Prediction via Stacking Ensemble ML Approach",
      description: "This awarded research addresses the challenge of using diverse data types - clinical, proteomic, and metabolomic - to predict COVID-19 case severity. By implementing a stacking ensemble approach with each of the three ML models representing a different data type, this offers a more comprehensive perspective on COVID-19 severity prediction.",
      image: "assets/COVID-severity.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/covid-severity-data-stacked-ensemble"
    },
    {
      title: "Alzheimer's Disease Neurological Change (ADNC) Prediction",
      description: "Leveraged the Seattle Alzheimer's Disease Brain Cell Atlas (SEA-AD) data, including neuropathological data, to predict ADNC severity with machine learning. Interpretability of ML model was visualized using LIME and SHAP techniques.",
      image: "assets/ad-severity.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/adnc-severity-interpretable-ml"
    },
    {
      title: "Association between Kidney Disease and Depression",
      description: "Investigating the relationship between kidney disease and depression in the US population, using data from the Behavioral Risk Factor Surveillance System (BRFSS) 2021. Univariate and multivariate logistic regression analyses, considering a complex survey design, were conducted to explore this association.",
      image: "assets/assoc-kid-dep.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/kidney-disease-depression-analysis"
    },
    {
      title: "Computational Drug Discovery",
      description: "Built machine learning models using ChEMBL bioactivity data with a target protein, beta-secretase 1, to make predictions on pIC50 and data-driven insights useful for drug discovery.",
      image: "assets/comp-drug-disc.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/computational-drug-disc"
    },
    {
      title: "Flower Image Classifier",
      description: "A custom image classifier built with PyTorch. It utilizes a VGG-16 CNN architecture to train a model on a flower image dataset. The trained classifier predicts flower type based on input images.",
      image: "/assets/flower-classifier.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/final_flower_classifier"
    },
    {
      title: "Double Cropped Acreage Variation in Southeastern NC",
      description: "A research project focused on analyzing USDA cropland data in southeastern NC and fitting econometric models based on the relationship between double-cropped acreage and commodity prices.",
      image: "/assets/econometric.png",
      icon: faFileExcel,
      button: "Poster",
      link: "/assets/econseminar.pdf"
    },
    {
      title: "Dog Breed Image Classifier",
      description: "A pre-trained dog breed image classifier that identifies dog images and dog breeds. It also includes functions to determine which CNN architectures work best for specific criteria.",
      image: "/assets/dog-classifier.png",
      icon: faPython,
      button: "Github",
      link: "https://github.com/kiannaamaya/Pre-trained-Dog-Breed-Classifier"
    },
  ];

 
  return (
    <section className="projects">
      <div className="projectsContainer">
        <h1 className="myProjectsTitle">My Projects</h1>
        <div className="grid projectsContent">
          {projectData.map((project, index) => (
            <div className="row row-eq-height projects" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 order-2 order-lg-1 projectInfo">
                    <h2 className="projectTitle">{project.title}</h2>
                    <p className="projectDescription">{project.description}</p>
                    <FontAwesomeIcon icon={project.icon} className="icon-large" /> <br />

                    <a
                      className="projectButton"
                      href={project.link}
                      role="button"
                      target="_blank"
                    >
                      {project.button}
                    </a>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                    <img className="projectImage img-fluid" src={project.image} alt={project.title} />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 order-1 order-lg-1 d-flex align-items-center justify-content-center">
                    <img className="projectImage img-fluid" src={project.image} alt={project.title} />
                  </div>
                  <div className="col-lg-6 order-2 order-lg-2 projectInfo">
                    <h2 className="projectTitle">{project.title}</h2>
                    <p className="projectDescription">{project.description}</p>
                    <FontAwesomeIcon icon={project.icon} className="icon-large" /> <br />

                    <a
                      className="projectButton"
                      href={project.link}
                      role="button"
                      target="_blank"
                    >
                      {project.button}
                    </a>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
  

};

export default Projects;
