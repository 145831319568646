import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // new state variable

  const handleToggle = () => {
    setIsOpen(!isOpen); // toggle the value of isOpen when the button is clicked
  };

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">Kianna Hendricks</Link> 
        <button onClick={handleToggle} className="navbar-toggler" type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link> 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link> 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/cv">CV</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/projects">Projects</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/kiannaexplainsai">Kianna Explains AI</Link>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
