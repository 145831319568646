import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Hero.css'; 

const Hero = () => {
  const phrases = ["I'm Kianna.", "I love coding.", "I love learning."];
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const isEndOfPhrase = charIndex === phrases[phraseIndex].length;

    if (isEndOfPhrase && !isDeleting) {
      setTimeout(() => setIsDeleting(true), 2000); // Delay before start deleting
      return;
    }

    if (isDeleting && charIndex === 0) {
      setIsDeleting(false);
      setPhraseIndex((value) => (value + 1) % phrases.length); // Go to the next phrase
      return;
    }

    const timer = setTimeout(() => {
      if (!isDeleting && charIndex < phrases[phraseIndex].length) {
        setCurrentPhrase((value) => value + phrases[phraseIndex][charIndex]);
        setCharIndex((value) => value + 1);
      }

      if (isDeleting && charIndex > 0) {
        setCurrentPhrase((value) => value.slice(0, -1));
        setCharIndex((value) => value - 1);
      }
    }, isDeleting ? 80 : 200);

    return () => clearTimeout(timer); // Clean up function
  }, [charIndex, phraseIndex, isDeleting, phrases]);

  return (
    <section className="heroSection">
    <div className="heroContainer">
      <div className="heroText">
        <h1 className="greeting">Hey,</h1>
        <h2 className="typewriterText">{currentPhrase}</h2>
        <p>I'm a Data Science and Engineering graduate student and self-taught web developer. I am thrilled by the endless possibilities 
          of data, and how it can change our understanding of the world around us. 🩷</p>
        <div className="buttonContainer">
          <Link to="/projects" className="researchButton">Check out my projects</Link>
        </div>
      </div>
    </div>
  </section>

  );
};

export default Hero;
