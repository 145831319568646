import React from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {

  const contactData = [
    {
      link: "https://github.com/kiannaamaya",
      icon: faGithub,
      label: "GitHub"
    },

    {
      link: "https://www.linkedin.com/in/kiannaamhendricks/",
      icon: faLinkedin,
      label: "LinkedIn"
    },
    {
      link: "mailto:iamkiannahendricks@gmail.com",
      icon: faEnvelope,
      label: "Email"
    },
    {
      link: "https://www.kiannaexplainsai.com",
      icon: faGlobe,
      label: "Kianna Explains AI"
    },
    // add the other links as needed...
  ];

  return (
    <footer className="contact">
      <div className="row align-items-center justify-content-center">
        <div>
          <h1 className="contact-title">Contact Me</h1>
        </div>
        <div className="contactLinks" id="contacts">
          {contactData.map((contact, index) => (
            <a
              id={`profile-link-${index}`}
              href={contact.link}
              target="_blank"
              className="btn contactDetails"
              key={index}
            >
              <FontAwesomeIcon icon={contact.icon} /> {contact.label}
            </a>
          ))}
        </div>
        <div className="attribution">&copy;2024 | Designed and Coded by Kianna Hendricks</div>
      </div>
    </footer>
  );
};

export default Contact;
