import React from 'react';
import './EyesOnTheData.css';


const EyesOnTheData = () => {
  return (
    <section className="eyes">
      <div className="eyesContainer">
        <div className="grid">
          <div className="row eyes">
            <div className="eyesText">
              <h1 className="title">Eyes on the Data</h1>
  
              <p>
              Explores and discusses the topic of surveillance and 
              police technology in the United States. My goal is to highlight the intricacies and ethical considerations of surveillance technologies. 
              By presenting in-depth analysis and dynamic visualizations, this site ultimately aims to foster an understanding of how these technologies
              affect marginalized communities and to advocate for responsible and ethical tech use.
              </p>
              <a
                className="projectButton"
                href="https://eyesonthedata.streamlit.app"
                role="button"
                target="_blank"
              >Check it out
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default EyesOnTheData;

