import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about">
      <h1 className="title">About Me</h1>
      <div className="contentContainer">
        <img className="aboutImage" src="assets/about-image.jpg" alt="Headshot of Kianna" />
        <div className="aboutText">
        <p>
              I am currently a 21-year-old graduate student at North Carolina A&amp;T State University, pursuing an MS in Data Science and Engineering. 
              After taking an R class, I found an interest in data science and its applications and furthered that interest. My research interests 
              include machine learning, bioinformatics, multi-omics, and neuroimaging.
              </p>
              <p>
              Beyond academics, I am committed to accessibility within data science. I have also created a platform, <b><a className="link" href="https://kiannaexplainsai.com" target="_blank" rel="noopener noreferrer">Kianna Explains AI</a></b>, where I aim 
              to break down AI topics for the general public from the perspective of a young graduate student. My goal is not just to contribute to 
              the academic world, but to ensure that data science is understandable, accessible, and beneficial for diverse communities across the globe.
              </p>
        </div>
      </div>
    </section>
  );
};

export default About;
